import React from "react";

function App() {
  return (
    <div>
      <h1>Songulashvili</h1>
      <ul>
        <li>React app</li>
        <li>Hosted by github pages</li>
        <li>Deployed by gh-pages package</li>
      </ul>
    </div>
  );
}

export default App;
